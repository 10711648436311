
.form-padding{

padding-top: 12px !important;
}

Form{

    width: 500px !important;
    margin: auto
}

button.btn.btn-primary{

    margin-top: 2px !important;
}