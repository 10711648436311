

.top {position: relative;
     padding-bottom: 56.25%;
     height: 0;
     overflow: hidden;
      max-width:100%;

}

.bottom {

    position: absolute;

     top: 0;
     
     left: 0;
     
     width: 100%;
     
     height: 575px;

}


.carousel-item .img-fluid {
     width:100% !important;
   }


.row-fluid{

     width: 100%
}

.carousel-item a {
     display: block;
     width:100%;
   }

   .imgstyle{
     width: 100px;
     height: 100px;
     border-radius: 50%;
   }

   .review-btn{

     float: right;
     margin-bottom: 22px;
     padding: 12px;
   }


   .review-btn a{

     text-decoration: none;
     color:#fff;
   }
   .rating-icons  path svg{

     width: 22px !important;
   }

   .rating svg{

     width: 22px !important
   }