.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: #dc3545 !important;
}

.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: #fff !important;
    text-decoration: none;
    background: 0 0;
    border: 0;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}


nav.bg-body-teritary.navbar.navbar-expand-lg.navbar-light{

    background-color: #151515!important
}

.list-group-item{
    border:none !important
}

div.text-center.list-group-item{

    margin-top: -10px;

}

.top-icons{

    display: flex;
}

.top-social{

    path {
      
    }
}


.top-icons{

  

        path{
          fill:crimson !important;
          width: 50px;
        height:50px;
        float:right !important;
        }

        
      
}

.my-top{

    background-color: #151515;
    border-bottom: 1px solid  #dc3545;
    padding-bottom: 15px;
    padding-top: 15px;

}

.hours-clock{
    font-size: 14px !important;
}
.list-group-item{

    background-color: #151515 !important;
    color: #fff !important;
}

a.dropdown-item{

    color:#dc3545 !important
}